<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="setting" />
				<span>系统</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 系统配置 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box baff">
			<a-form-model ref="configForm" :label-col="{ span: 10 }" :wrapper-col="{ span: 12 }" style="width: 600px">
				<a-row :gutter="24">
					<a-col :span="24" v-for="(item, index) in setttingList" :key="index">
						<a-form-model-item :label="item.title">
							<a-input v-model="item.value" v-if="item.type == 0" placeholder="" type="text" />
							<a-radio-group v-model="item.value" v-if="item.type == 1" name="radioGroup" :default-value="item.value">
								<a-radio value="0">
									关闭
								</a-radio>
								<a-radio value="1">
									开启
								</a-radio>
							</a-radio-group>
						</a-form-model-item>
					</a-col>
					<a-col :span="24" style="margin-top: 40px;">
						<a-button type="primary" class="marginr20" style="margin-left: 220px" html-type="submit" @click="saveSetting">
							确定
						</a-button>
					</a-col>
				</a-row>
			</a-form-model>
		</div>


	</div>
</template>

<script>
	import http from '../http'
	export default {
		data() {
			return {
				setttingList: []
			};
		},
		mounted() {
			this.getSettingList()
		},
		created() {
			this.getSettingList()
		},
		methods: {
			getSettingList() {
				http({
					method: 'post',
					url: '/api.config.sysconfig/',
					data: {}
				}).then((res) => {
					if (res.code == '200') {
						this.setttingList = res.data
					} else {
						this.$message.error('系统配置获取失败');
					}
				})
			},
			saveSetting () {
				http({
					method: 'post',
					url: '/api.config.sysadd/',
					data: {content: JSON.stringify(this.setttingList)}
				}).then((res) => {
					if (res.code == '200') {
						this.$message.success('设置系统参数成功');
					} else {
						this.$message.error('设置系统参数失败');
					}
				})
			},
		},
	};
</script>
<style lang="less">
	.baff {
		background-color: #fff;
		margin-top: 16px;
		padding-bottom: 100px;
		padding-top: 50px;
	}

	.nesw {
		margin: 0px;
		font-size: 12px;
		color: red;
		line-height: 16px;
	}
</style>
